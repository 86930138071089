@import '../../../GlobalStyles/variables';

.header {
    background-color: $primary-color5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    font-size: 16px;
    line-height: 40px;

    .info-icon {
        font-size: 28px;
        &:hover{
            cursor: pointer;
        }
    }
}

.title {
    color: $primary-color19;

    span {
        margin-left: 6px;
        font-weight: 600;
    }
    &:hover{
        cursor: pointer;
    }
}

.title,
.user {
    width: 30%;
    display: flex;
    align-items: center;
}

.user {
    display: flex;
    justify-content: end;
    font-weight: 600;
}

.nav {
    list-style: none;
    display: flex;
    justify-content: center;
}

.nav li {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover{
        cursor: pointer;
    }
}
.logout-modal {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    border-radius: 8px;
}

.logout-modal button {
    width: 120px;
    margin: 5px;
    padding: 8px 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    border: none;
    border-radius: 4px;
}

.logout-modal button:hover {
    background-color: #45a049;
}

.logout-modal button.cancel {
    background-color: #f44336;
}

.logout-modal button.cancel:hover {
    background-color: #d32f2f;
}