$primary-color0: #2e2f2f;
$primary-color1: #fbfcfd;
$primary-color2: #7ff77d;
$primary-color3: #eeeeee;
$primary-color4: #a9afbb;
$primary-color5: #ffffff;
$primary-color6: #fe9d18;
$primary-color7: #5ab25e;
$primary-color8: #eb4845;
$primary-color9: #16bbd0;
$primary-color10: #555555;
$primary-color11: #fefefe;
$primary-color12: #084892;
$primary-color13: #C5DEFD;
$primary-color14: #000000;
$primary-color15: #043c03;
$primary-color16: #cccccc;
$primary-color17: #8BE2BF;
$primary-color18: #00343B;
$primary-color19: #083d7c;
$primary-color20: #959698;
$primary-color21: #b8e3ff;
$primary-color22: #1976d2;
$primary-color23: #c6e6fb;
