@import "../../../Components/GlobalStyles/variables";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.popup {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}

.form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-size: 13px;

    input {
        width: 50%;
        margin-right: 8px;
    }

    label {
        display: block;
        font-weight: bold;
        margin: 0 4px 5px 4px;
        margin-bottom: 5px;
    }

    .error-message {
        color: red;
        font-size: 0.9em;
        margin-top: 5px;
    }

    button:hover {
        cursor: pointer;
        background: $primary-color8;
    }
    .total-questions{
        display: flex;
        flex-direction: row;
        line-height: 100%;
        margin-right: 16px;
        span{
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.error-message {
    color: red;
    font-size: 0.9em;
    margin: 2px 0 5px 0;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .add,
    .cancel {
        padding: 10px 20px;
        font-size: 1em;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
            opacity: 0.8;
        }
    }

    .add {
        background-color: #4caf50;
        color: white;
    }

    .cancel {
        background-color: #f44336;
        color: white;
    }
}