@import "../../Components/GlobalStyles/variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.body-login {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("../../assets/background.jpg");
  background-size: cover;
}

.wrapper {
  width: 420px;
  //   background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 10px;
  padding: 30px 40px;

  h1 {
    font-size: 36px;
    text-align: center;
    font-weight: 700;
  }

  button {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #333;
    font-weight: 700;
    &:hover{
      cursor: pointer;
      scale: 1.02;
    }
  }

  .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: 0 0 15px;
    label {
      margin: 4px;
      display: flex;
      align-items: center;
      white-space: nowrap; // Prevents the text from wrapping to the next line
      input{
        margin-right: 8px;
      }
    }

  }

  .input-box input::placeholder {
    color: #fff;
  }

  .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      outline: none;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 40px;
      font-size: 16px;
      color: #fff;
      padding: 20px 45px 20px 20px;
    }

    .icon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
    }

    .icon-container {
      cursor: pointer;
    }
  }

  .login-error {
    color: red;
    margin: -15px 0 20px;
  }
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Màu đen với độ trong suốt 50%
  z-index: 9999; // Đảm bảo overlay nằm phía trên tất cả các phần tử khác
}

.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); // Đưa spinner vào giữa trung tâm màn hình
  z-index: 10000; // Đảm bảo spinner nằm phía trên overlay
}